<template>
  <div id="burger" :class="{ active: !isNavClose }" @click.prevent="toggle">
    <slot>
      <button type="button" class="burger-button" title="Menu">
        <span class="burger-bar burger-bar--1"></span>
        <span class="burger-bar burger-bar--2"></span>
        <span class="burger-bar burger-bar--3"></span>
      </button>
    </slot>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters("sidebar", ["isNavClose"]),
    isBurgerActive() {
      return !this.isNavClose;
    },
  },
  methods: {
    ...mapActions("sidebar", ["saveNavbar"]),

    toggle() {
      this.saveNavbar(!this.isNavClose);
      const el = document.querySelector(".wizard-footer-right");
      if (el) {
        const w = document.querySelector(".dashboard").clientWidth;
        el.style.width = `${
          this.isNavClose ? w + (240 - 50) : w - (240 - 50)
        }px`;
      }
    },
  },
};
</script>
<style>
.hidden {
  visibility: hidden;
}

#burger {
  margin-left: 0px;
  padding-left: 1rem;
}

button {
  cursor: pointer;
}

button:focus {
  outline: 0;
}

.burger-button {
  position: relative;
  height: 30px;
  width: 30px;
  display: block;
  z-index: 1;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  pointer-events: all;
  margin-left: 0px;
  margin-right: 15px;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.burger-bar {
  background-color: white;
  position: absolute;
  top: 50%;
  right: 1px;
  left: 1px;
  height: 3px;
  width: auto;
  margin-top: -1px;
  border-radius: 100vmax;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
    background-color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.burger-bar--1 {
  -webkit-transform: translateY(-6px);
  transform: translateY(-6px);
}

.burger-bar--2 {
  transform-origin: 100% 50%;
  transform: scaleX(1);
}

.burger-bar--3 {
  transform: translateY(6px);
}

#burger.active .burger-button {
  transform: rotate(-180deg);
}

#burger.active .burger-bar {
  background-color: white;
}

#burger.active .burger-bar--1 {
  transform: rotate(45deg);
}

#burger.active .burger-bar--2 {
  opacity: 0;
}

#burger.active .burger-bar--3 {
  transform: rotate(-45deg);
}
</style>
