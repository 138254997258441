// PLEASE!!! keep alphabetically sorted!
// NOTE: names must be singular snake_case
import AddressBook from "@/repositories/address_book";
import AgencyBook from "@/repositories/agency_book";
import AgencyContact from "@/repositories/agency_contact";
import AgencySetup from "@/repositories/agency_setup";
import Analysis from "@/repositories/analysis";
import Auth from "@/repositories/auth";
import BankAccount from "@/repositories/bank_account";
import BookEntry from "@/repositories/book_entry";
import BookTag from "@/repositories/book_tag";
import Breakdown from "@/repositories/breakdown";
import Broker from "@/repositories/broker";
import BrokerStatement from "@/repositories/broker_statement";
import CashEntry from "@/repositories/cash_entry";
import CashReference from "@/repositories/cash_reference";
import Claim from "@/repositories/claim";
import ClaimParty from "@/repositories/claim_party";
import Commission from "@/repositories/commission";
import CommunicationProcedure from "@/repositories/communication_procedure";
import CommunicationGrouping from "@/repositories/communication_grouping";
import CommunicationDetail from "@/repositories/communication_detail";
import ConsolidatedTreasury from "@/repositories/consolidated_treasury";
import Correspondence from "@/repositories/correspondence";
import CustomAttribute from "@/repositories/custom_attribute";
import DirectDebit from "@/repositories/direct_debit";
import DataProtection from "@/repositories/data_protection";
import Document from "@/repositories/document";
import EntryDetail from "@/repositories/entry_detail";
import Fattutto from "@/repositories/fattutto";
import Filter from "@/repositories/filter";
import ForgotPassword from "@/repositories/forgot_password";
import GeneratedReport from "@/repositories/generated_report";
import Importer from "@/repositories/importer";
import ImportDetail from "@/repositories/import_detail";
import ImportFile from "@/repositories/import_file";
import ImportLog from "@/repositories/import_log";
import ImportMaps from "@/repositories/import_map";
import Index from "@/repositories/index";
import InsuranceAncillary from "@/repositories/insurance_ancillary";
import InsurancePayment from "@/repositories/insurance_payment";
import InsurancePolicy from "@/repositories/insurance_policy";
import InsuranceRisk from "@/repositories/insurance_risk";
import Insurer from "@/repositories/insurer";
import JobSpool from "@/repositories/job_spool";
import InsurerParticipant from "@/repositories/insurer_participant";
import LisaForApp from "@/repositories/lisa_for_app";
import MandateCode from "@/repositories/mandate_code";
import PaymentReference from "@/repositories/payment_reference";
import PaymentProcedure from "@/repositories/payment_procedure";
import Registry from "@/repositories/registry";
import RegistryGroup from "@/repositories/registry_group";
import RelativeType from "@/repositories/relative_type";
import Report from "@/repositories/report";
import ResetPassword from "@/repositories/reset_password";
import Sector from "@/repositories/sector";
import Task from "@/repositories/task";
import TempImport from "@/repositories/temp_import";
import Treasury from "@/repositories/treasury";
import UnsolvedPayment from "@/repositories/unsolved_payment";
import User from "@/repositories/user";
import VariousAccounting from "@/repositories/various_accounting";
import ContoPrimaNota from "@/repositories/conto-prima-nota";

// PLEASE!!! keep alphabetically sorted!
const repositories = {
  address_book: AddressBook,
  agency_book: AgencyBook,
  agency_contact: AgencyContact,
  agency_setup: AgencySetup,
  analysis: Analysis,
  auth: Auth,
  bank_account: BankAccount,
  book_entry: BookEntry,
  book_tag: BookTag,
  breakdown: Breakdown,
  broker_statement: BrokerStatement,
  broker: Broker,
  cash_entry: CashEntry,
  cash_reference: CashReference,
  claim_party: ClaimParty,
  claim: Claim,
  commission: Commission,
  communication_procedure: CommunicationProcedure,
  communication_grouping: CommunicationGrouping,
  communication_detail: CommunicationDetail,
  consolidated_treasury: ConsolidatedTreasury,
  correspondence: Correspondence,
  custom_attribute: CustomAttribute,
  direct_debit: DirectDebit,
  data_protection: DataProtection,
  document: Document,
  entry_detail: EntryDetail,
  fattutto: Fattutto,
  filter: Filter,
  forgot_password: ForgotPassword,
  generated_report: GeneratedReport,
  import_detail: ImportDetail,
  import_file: ImportFile,
  import_log: ImportLog,
  import_map: ImportMaps,
  importer: Importer,
  index: Index,
  insurance_ancillary: InsuranceAncillary,
  insurance_payment: InsurancePayment,
  insurance_policy: InsurancePolicy,
  insurance_risk: InsuranceRisk,
  insurer: Insurer,
  job_spool: JobSpool,
  insurer_participant: InsurerParticipant,
  lisa_for_app: LisaForApp,
  mandate_code: MandateCode,
  payment_reference: PaymentReference,
  payment_procedure: PaymentProcedure,
  registry_group: RegistryGroup,
  registry: Registry,
  relative_type: RelativeType,
  report: Report,
  reset_password: ResetPassword,
  sector: Sector,
  task: Task,
  temp_import: TempImport,
  treasury: Treasury,
  unsolved_payment: UnsolvedPayment,
  user: User,
  various_accounting: VariousAccounting,
  conto_prima_nota: ContoPrimaNota,
  // other repositories ...
};

export const RepositoryFactory = {
  get: (name) => repositories[name],
};
