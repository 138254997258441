<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 160 110.963">
    <title>Fattutto</title>
    <path
      d="M145.973 53.242l-2.494-1.639-.157-3.007C142.05 24.262 124.853 4.773 100.97.598 98.535.172 90.277-.163 88.271.083c-15.844 1.94-28.316 9.018-36.745 20.854l-1.498 2.103-3.931-.138c-21.994-.775-40.355 13.359-45.08 34.7-5.708 25.787 13.296 51.286 39.555 53.072 7.305.497 89.091.308 92.589-.214 28.429-4.24 36.766-41.475 12.811-57.218zm-12.922 47.392c-3.011.621-90.833.69-94.448.073-21.971-3.744-34.807-26.854-26.174-47.12 6.688-15.7 22.926-23.883 39.884-20.099 1.428.319 2.626.546 2.662.506s.606-1.052 1.267-2.247c8.852-16.019 25.297-24.323 42.783-21.603 16.024 2.493 29.348 14.654 33.295 30.392 1.264 5.041 1.429 7.9.952 16.559l-.041.75 2.871 1.203c9.248 3.876 14.082 10.939 14.046 20.52-.039 10.455-6.952 18.973-17.098 21.067zM101.475 48.45H68.429l-1.208 13.158h8.111l-7.507 27.308h17.127l5.824-27.351h9.318l1.381-13.115zm2.329-8.24l2.071-14.042c-5.436-1.747-11.26-2.33-16.436-.841-6.625 1.905-8.777 12.763-9.512 17.86h16.695c.777-6.989 7.183-2.977 7.183-2.977z"
    />
  </svg>
</template>

<script>
export default {};
</script>
