<template>
  <div class="drawer">
    <div class="notifications-drawer">
      <div
        id="NotificationsDrawer-Overlay"
        class="drawer__overlay"
        @click="onClickCloseNotificationsDrawer()"
      ></div>
      <div
        class="drawer__inner"
        role="dialog"
        aria-modal="true"
        aria-label="Notifiche"
        tabindex="-1"
      >
        <div class="drawer__header">
          <div class="drawer__heading inner-title">
            Promemoria{{
              notifications.length ? ` (${notifications.length})` : ""
            }}
          </div>
          <button
            class="drawer__close"
            type="button"
            @click="onClickCloseNotificationsDrawer()"
            aria-label="Chiudi"
          >
            <base-icon name="close" width="12" height="12" />
          </button>
        </div>
        <div
          :class="`notifications-drawer-items${
            !notifications.length ? ' is-empty' : ''
          }`"
        >
          <template v-if="notifications.length">
            <b-overlay :show="isLoadingNotifications">
              <div v-show="!isLoadingNotifications">
                <div
                  class="notification-item"
                  v-for="(item, index) in notifications"
                  :key="index"
                >
                  <div class="notification-item__inner">
                    <p class="notification-item__title">
                      <strong>{{ item.title }}</strong>
                    </p>
                    <p v-if="item.description" class="notification-item__desc">
                      {{ item.description }}
                    </p>
                    <div class="custom-grid-wrapper">
                      <template
                        v-if="
                          Object.prototype.hasOwnProperty.call(
                            item,
                            'task_type'
                          )
                        "
                      >
                        <strong>Tipologia</strong
                        ><span class="notification-item__type">{{
                          item.task_type.text
                        }}</span>
                      </template>
                      <template
                        v-if="item.started_at && item.task_type.value === 0"
                      >
                        <strong>Data inizio</strong
                        ><span class="notification-item__start">{{
                          moment(item.started_at).format("DD/MM/YYYY HH:mm:ss")
                        }}</span>
                      </template>
                      <template
                        v-if="item.ended_at && item.task_type.value === 0"
                      >
                        <strong>Data fine</strong
                        ><span class="notification-item__start">{{
                          moment(item.ended_at).format("DD/MM/YYYY HH:mm:ss")
                        }}</span>
                      </template>
                      <template
                        v-if="item.status_task && item.task_type.value === 1"
                      >
                        <strong>Stato</strong
                        ><span class="notification-item__status">{{
                          item.status_task.text
                        }}</span>
                      </template>
                      <template
                        v-if="
                          Object.prototype.hasOwnProperty.call(
                            item,
                            'is_public'
                          ) &&
                          Object.prototype.hasOwnProperty.call(
                            item.is_public,
                            'text'
                          )
                        "
                      >
                        <strong>È pubblico</strong
                        ><span class="notification-item__start">{{
                          item.is_public.text
                        }}</span>
                      </template>
                      <template v-if="item.owner_id != null && item.created_at">
                        <strong>Attività aperta da</strong
                        ><span
                          class="notification-item__users"
                          v-html="getOwner(item.owner_id, item.created_at)"
                        ></span>
                      </template>
                      <template v-if="item.users.length">
                        <strong>Attività condivisa con</strong
                        ><span class="notification-item__users">
                          {{ getUsersList(item.users) }}</span
                        >
                      </template>
                    </div>
                    <div class="notification-item__actions">
                      <!-- <b-button
                        v-if="
                          Object.prototype.hasOwnProperty.call(item, 'memo') &&
                          Object.prototype.hasOwnProperty.call(item.memo, 'id')
                        "
                        size="sm"
                        variant="lisaweb"
                        @click="viewTask(item, item.task_type.value === 0)"
                        class="mr-1"
                        :title="`Visualizza ${item.task_type.text}`"
                      >
                        <b-icon icon="eye"></b-icon>
                      </b-button> -->
                      <b-button
                        v-if="
                          isDeletable(item.owner_id) &&
                          Object.prototype.hasOwnProperty.call(item, 'memo') &&
                          Object.prototype.hasOwnProperty.call(item.memo, 'id')
                        "
                        size="sm"
                        variant="lisaweb"
                        @click.stop="deleteMemo(item.memo.id)"
                        title="Elimina promemoria"
                      >
                        <b-icon icon="trash"></b-icon>
                      </b-button>
                    </div>
                  </div>
                  <!-- <div
                    class="notification-item__inner"
                    v-html="renderNotificationItem(item)"
                  ></div> -->
                </div>
              </div>
              <template #overlay>
                <div class="text-center mt-6">
                  <base-icon name="loading" width="35" height="35" />
                  <p id="cancel-label">Operazione in corso...</p>
                </div>
              </template>
            </b-overlay>
          </template>
          <template v-else
            ><p><i>Non ci sono promemoria</i></p></template
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import CalendarEventsAndTasksMixin from "@/mixins/CalendarEventsAndTasksMixin";
import { mapGetters } from "vuex";
import moment from "moment";
moment.locale("it");

export default {
  mixins: [CalendarEventsAndTasksMixin],
  data() {
    return {
      repository: "task",
    };
  },
  props: {
    notifications: {
      type: Array,
      default() {
        return [];
      },
    },
    isLoadingNotifications: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BaseIcon,
  },
  computed: {},
  watch: {
    isLoadingNotifications: function (newVal, oldVal) {
      // watch it
      console.log(
        `Prop "isLoadingNotifications" changed: , ${newVal}, | was: , ${oldVal}`
      );
    },
  },
  methods: {
    moment,
    isDeletable(id) {
      const user = this.getUserOwner(id);
      return user.value == this?.$store?.state?.auth?.user?.id ? true : false;
    },
    getUserOwner(id) {
      return this.getUsers().find((user) => user.value === id);
    },
    getOwner(id, created_at) {
      let ret = "";
      const user = this.getUserOwner(id);
      if (!user) return;
      ret = user?.username;
      if (!ret) return "";
      ret += ` | ${moment(created_at).format("DD/MM/YYYY HH:mm:ss")}`;
      return ret;
    },
    getUsersList(users) {
      if (!users || !users.length) return "";
      let ret = "";
      const user_data = this.getUsers()
        .filter((user) => users.map((el) => el.id).includes(user.value))
        .map((user) => user.username);
      ret = `${user_data ? user_data.join(", ") : ""}`;
      return ret;
    },
    deleteMemo(id) {
      if (!id) return;
      this.$emit("isLoadingNotificationsTrue");
      console.log("id", id);
      const Repo = RepositoryFactory.get("task");
      Repo.destroy_memo(id)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: "Promemoria rimosso",
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.$emit("reloadNotificationsDrawer");
        });
    },
    // renderNotificationItem(item) {
    //   // console.log("item", item);
    //   return `
    //     <p class="notification-item__title"><strong>${item.title}</strong></p>
    //     ${
    //       item.description
    //         ? `<p class="notification-item__desc">${item.description}</p>`
    //         : ""
    //     }
    //     <div class="custom-grid-wrapper">
    //     ${
    //       Object.prototype.hasOwnProperty.call(item, "task_type")
    //         ? `<strong>Tipologia</strong><span class="notification-item__start">${item.task_type.text}</span>`
    //         : ""
    //     }
    //     ${
    //       item.started_at
    //         ? `<strong>Data inizio</strong><span class="notification-item__start">${moment(
    //             item.started_at
    //           ).format("DD/MM/YYYY HH:mm:ss")}</span>`
    //         : ""
    //     }
    //     ${
    //       item.ended_at
    //         ? `<strong>Data fine</strong><span class="notification-item__end">${moment(
    //             item.ended_at
    //           ).format("DD/MM/YYYY HH:mm:ss")}</span>`
    //         : ""
    //     }
    //     </div>
    //   `;
    // },
    onClickCloseNotificationsDrawer() {
      this.$emit("closeNotificationsDrawer");
    },
    ...mapGetters("auth", {
      getUsers: "users",
    }),
  },
  beforeMount() {},
};
</script>
<style lang="scss" scoped>
#app.drawer-notifications--open .drawer {
  visibility: visible;
}
#app.drawer-notifications--open .drawer__inner {
  transform: translateX(0);
}
#app.drawer-notifications--open .drawer__overlay {
  opacity: 1;
}
.drawer {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  visibility: hidden;
  background: 0;
  transition: visibility 200ms ease;
}
.drawer.is-empty {
  display: grid;
  grid-template-rows: 1fr;
  align-items: center;
  padding: 0;
}
// .drawer.active {
//   visibility: visible;
// }
// .drawer.active .drawer__inner {
//   transform: translateX(0);
// }
.drawer__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(18, 18, 18, 0.5);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.drawer__overlay:empty {
  display: block;
}
.drawer__inner {
  width: 40vw;
  max-width: 500px;
  height: 100vh;
  max-height: 100vh;
  margin-top: 0;
  border: 0;
  background: #fff;
  box-shadow: -1px 0 0 0 #eaeaea;
  transform: translateX(100%);
  transition: all 0.3s ease-in-out;
}
.drawer__close {
  border: 0;
  background: 0;
}
.drawer__header {
  border-bottom: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  font-size: 16px;
  font-weight: bold;
}
.notifications-drawer-items.is-empty {
  height: 100%;
  padding: 0 1.5rem;
  background-color: rgb(var(--color-background));
  overflow: hidden;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.notifications-drawer-items.is-empty > p {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  margin: 0;
}
.drawer .notifications-drawer-items:not(.is-empty) {
  overflow-y: scroll;
  height: calc(100vh - 59px);
  padding: 20px;
}
.drawer .notification-item {
  padding-bottom: 20px;
  // border-bottom: 1px solid #eaeaea;
}
.drawer .notification-item ~ .notification-item {
  padding-top: 20px;
}
.drawer div > .notification-item:nth-of-type(even) {
  background: #f5f5f5;
  box-shadow: 0 0 0 100vmax #f5f5f5;
  clip-path: inset(0 -100vmax);
}
.drawer .notification-item .notification-item__title,
.drawer .notification-item .notification-item__desc {
  margin-bottom: 10px;
}
.drawer
  .notification-item:has(.notification-item__desc)
  .notification-item__title {
  margin-bottom: 0;
}
.drawer .notification-item .notification-item__title {
  grid-area: 1 / 1 / 2 / 3;
}
.drawer .notification-item .notification-item__desc {
  grid-area: 2 / 1 / 3 / 3;
}
.drawer .notification-item .custom-grid-wrapper {
  grid-template-columns: 125px minmax(auto, max-content);
  grid-row-gap: 5px;
  grid-column-gap: 18px;
  grid-area: 2 / 1 / 3 / 2;
}
.drawer .notification-item .notification-item__desc ~ .custom-grid-wrapper {
  grid-area: 3 / 1 / 4 / 2;
}
.drawer .notification-item__inner {
  display: grid;
  // grid-template-columns: repeat(2, 1fr);
  // grid-template-rows: repeat(2, 1fr);
  grid-template-columns: auto 38px;
  grid-auto-flow: row;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.drawer .notification-item__actions {
  grid-area: 2 / 2 / 3 / 3;
  align-self: flex-end;
  justify-self: flex-end;
}
.drawer .notification-item__desc ~ .notification-item__actions {
  grid-area: 3 / 2 / 4 / 3;
}
.drawer .notification-item__users ul {
  padding-left: 16px;
  margin: 0;
}
@media (hover: none) and (pointer: coarse) {
  .drawer .notifications-drawer-items:not(.is-empty) {
    -webkit-overflow-scrolling: touch;
  }
}
</style>
