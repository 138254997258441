export default [
  {
    path: "/fattutto",
    name: "fattutto.index",
    meta: {
      auth: true,
      module: "module.FATT",
      submodule: "",
      hasAccess: true, // no permission check (a settings page is unknown at backend-side)
    },
    component: () =>
      import(
        /* webpackChunkName: "fattutto" */ "@/views/fattutto/Management.vue"
      ),
  },
];
