<template>
  <div
    id="app"
    :class="`${showNotificationsDrawer ? 'drawer-notifications--open' : ''}`"
  >
    <accordion-sidebar v-show="!$store.state.sidebar.locked" />
    <div class="dashboard">
      <custom-header
        :loadingNotifications="loadingNotifications"
        :notifications="countNotifications"
        v-if="isLogged"
        @openNotificationsDrawer="openNotificationsDrawer"
        @closeKeyUpNotificationsDrawer="closeNotificationsDrawer"
        @loadNotifications="loadNotifications"
      ></custom-header>
      <transition name="component-fade" mode="out-in">
        <router-view />
      </transition>
    </div>
    <notifications-drawer
      :notifications="notifications"
      v-if="isLogged"
      @closeNotificationsDrawer="closeNotificationsDrawer"
      @reloadNotificationsDrawer="openNotificationsDrawer"
      @isLoadingNotificationsTrue="isLoadingNotificationsTrue"
      :isLoadingNotifications="isLoadingNotifications"
      :ref="notificationsDrawerRef"
    ></notifications-drawer>
  </div>
</template>

<script>
// // import { showSnackbar } from "@/utils/messages";
import AccordionSidebar from "@/components/AccordionSidebar.vue";
import NotificationsDrawer from "@/components/NotificationsDrawer.vue";
import CustomHeader from "@/components/CustomHeader.vue";
import { mapGetters, mapActions } from "vuex";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";

export default {
  data() {
    return {
      showNotificationsDrawer: false,
      loadingNotifications: false,
      isLoadingNotifications: false,
      countNotifications: 0,
      notifications: [],
      notificationsDrawerRef: "notificationDrawer",
    };
  },
  components: {
    CustomHeader,
    AccordionSidebar,
    NotificationsDrawer,
  },
  computed: {
    ...mapGetters("sidebar", ["isNavClose"]),
    ...mapGetters("auth", ["isLogged", "permissions"]),
    ...mapActions("tabs", { removeAllTabs: "removeAll" }),
  },
  methods: {
    loadNotifications() {
      this.loadingNotifications = true;
      const Repo = RepositoryFactory.get("task");
      Repo.show_memo()
        .then((response) => {
          this.countNotifications = response?.data?.data?.length;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.loadingNotifications = false;
        });
    },
    isLoadingNotificationsTrue() {
      this.isLoadingNotifications = true;
    },
    keyUpFn(e) {
      // ESC
      if (e.keyCode === 27) this.closeNotificationsDrawer();
    },
    openNotificationsDrawer() {
      this.loadingNotifications = true;
      const Repo = RepositoryFactory.get("task");
      Repo.show_memo()
        .then((response) => {
          this.notifications = response?.data?.data;
          this.countNotifications = response?.data?.data?.length;
          this.showNotificationsDrawer = true;
          this.$refs[this.notificationsDrawerRef].$el.focus();
          document.addEventListener("keyup", this.keyUpFn);
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.loadingNotifications = false;
          this.isLoadingNotifications = false;
        });
    },
    closeNotificationsDrawer() {
      if (!this.showNotificationsDrawer) return;
      this.showNotificationsDrawer = false;
      document.removeEventListener("keyup", this.keyUpFn);
    },
    preventF5(event) {
      event.preventDefault();
      event.returnValue = "";
    },
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventF5);
  },
};
</script>

<style lang="scss">
#app {
  font-family: Arial, Helvetica, sans-serif;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  //   "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  //   "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  height: 100vh;
  overflow-x: hidden;
}

.dashboard {
  flex: 1;
  margin-left: 0;
  transition: transform 0.2s linear;
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
