// customisations for vee-validate

// import { required, confirmed, length, email } from "vee-validate/dist/rules";
// import { required } from "vee-validate/dist/rules";
import {
  extend,
  // required,
  localize /*setInteractionMode*/,
} from "vee-validate";
import it from "vee-validate/dist/locale/it.json";
import * as rules from "vee-validate/dist/rules";
import moment from "moment";
moment.locale("it");
// Install VeeValidate rules and localization
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

localize("it", it);

// setInteractionMode("aggressive");

const operator_compare_date = {
  ">": function (a, b) {
    return moment(a, "DD/MM/YYYY") > moment(b, "DD/MM/YYYY");
  },
  "<": function (a, b) {
    return moment(a, "DD/MM/YYYY") < moment(b, "DD/MM/YYYY");
  },
  ">=": function (a, b) {
    return moment(a, "DD/MM/YYYY") >= moment(b, "DD/MM/YYYY");
  },
  "<=": function (a, b) {
    return moment(a, "DD/MM/YYYY") <= moment(b, "DD/MM/YYYY");
  },
  "=": function (a, b) {
    return moment(a, "DD/MM/YYYY") === moment(b, "DD/MM/YYYY");
  },
  "!=": function (a, b) {
    return moment(a, "DD/MM/YYYY") !== moment(b, "DD/MM/YYYY");
  },
  "<>": function (a, b) {
    return moment(a, "DD/MM/YYYY") !== moment(b, "DD/MM/YYYY");
  },
};

const operator_compare_number = {
  ">": function (a, b) {
    return a > b;
  },
  "<": function (a, b) {
    return a < b;
  },
  ">=": function (a, b) {
    return a >= b;
  },
  "<=": function (a, b) {
    return a <= b;
  },
  "=": function (a, b) {
    return a === b;
  },
  "!=": function (a, b) {
    return a != b;
  },
  "<>": function (a, b) {
    return a != b;
  },
};

// extend("required", {
//   ...required,
//   message: (field) => `Il campo ${field} è richiesto`,
// });

extend("between", {
  validate: (value, obj) => {
    // value must be > zero
    let v =
      typeof value === "string" ? parseFloat(value.replace(",", ".")) : value;
    // console.debug(`between: ${obj.min} < ${v} < ${obj.max}`);
    if (v >= obj.min && v <= obj.max) return true;
    return false;
  },
  // message: (field, args) => field + ` deve essere compreso tra ${[args["0"]]}`,
  message: (field, args) =>
    `Il campo ${field} deve essere compreso tra ${[args.min]} e ${[args.max]}`,
});

extend(
  "required_with",
  {
    validate(value, [otherValue]) {
      let required = !!value && !otherValue;
      // console.debug(
      //   `value: "${value}", otherValue: "${otherValue}", result: ${
      //     required ? "true" : "false"
      //   }`
      // );
      return !required;
      // let required = !value && target;
      // if (!required) {
      // return {
      //   valid: required,
      //   data: {
      //     required,
      //   },
      // };
      // }
    },
    // message: "Il campo {_field_} è collegato",
    message: (field) => `Il campo ${field} è obbligatorio`,
    // message: (field, args) => `Il campo è collegato a ${JSON.stringify(args)}`,
  },
  {
    hasTarget: true,
    computesRequired: true,
  }
);

// const dict = {
//   custom: {
//     email: {
//       required: "Your email is empty",
//     },
//     password: {
//       required: () => "Your password is empty",
//     },
//   },
// };

// localize("it", dict);

// extend("email", {
//   ...email,
//   message: "This field must be a valid email",
// });

// extend("confirmed", {
//   ...confirmed,
//   message: "This field confirmation does not match",
// });

// extend("length", {
//   ...length,
//   message: "This field must have 2 options",
// });

extend("required", {
  // ...required,
  message: (field) => `Il campo ${field} è richiesto`,
  validate(value) {
    const valid =
      (!Array.isArray(value) &&
        !["", null, undefined, false].includes(value)) ||
      (Array.isArray(value) && value.length > 0);
    return {
      required: true,
      valid: valid,
    };
  },
  computesRequired: true,
});
extend(
  "compare_date",
  {
    validate: (value, { otherValue, operator }) => {
      if (otherValue === "today") {
        otherValue = moment().format("DD/MM/YYYY");
      }
      return operator_compare_date[operator](value, otherValue);
    },
    params: ["otherValue", "operator"],
    message: (field, { otherValue, operator }) =>
      `${field} deve essere ${operator} ${otherValue}`,
  },
  {
    hasTarget: true,
    computesRequired: true,
  }
);
extend("compare_number", {
  validate: (value, { otherValue, operator }) => {
    let v =
      typeof value === "string"
        ? parseFloat(value.replace(",", "."))
        : parseFloat(value);
    let ov =
      typeof otherValue === "string"
        ? parseFloat(otherValue.replace(",", "."))
        : parseFloat(otherValue);
    // console.debug("compare_number: ", v, ov);
    return operator_compare_number[operator](v, ov);
  },
  params: ["otherValue", "operator"],
  message: (field, { otherValue, operator }) =>
    `${field} deve essere ${operator} ${otherValue}`,
});

extend("compare_numbers", {
  validate: (value, { otherValues, operator }) => {
    let allValid = true;
    let v =
      typeof value === "string"
        ? parseFloat(value.replace(",", "."))
        : parseFloat(value);
    otherValues.forEach((otherValue) => {
      let ov =
        typeof otherValue === "string"
          ? parseFloat(otherValue.replace(",", "."))
          : parseFloat(otherValue);
      allValid &&= operator_compare_number[operator](v, ov);
      // console.debug("compare_numbers: ", v, ov);
    });
    return allValid;
  },
  params: ["otherValues", "operator"],
  message: (field, { otherValues, operator }) =>
    `${field} deve essere ${operator} ${otherValues.join(",")}`,
});
