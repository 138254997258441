<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511 512.32">
    <title>Invia</title>
    <path
      d="M9.72 185.88L489.19 1.53c3.64-1.76 7.96-2.08 12.03-.53A15.17 15.17 0 0 1 510 20.57L326.47 502.56h-.02c-1.33 3.49-3.94 6.5-7.57 8.25-7.54 3.63-16.6.47-20.23-7.06l-73.78-152.97 146.67-209.97-209.56 146.3L8.6 213.64a15.117 15.117 0 0 1-7.6-8.25 15.12 15.12 0 0 1 8.72-19.51z"
      fill="#fff"
    />
  </svg>
</template>

<script>
export default {};
</script>
