import Vue from "vue";
import {RepositoryFactory} from "@/repositories/RepositoryFactory";
// let AuthRepository = RepositoryFactory.get("auth");
// AuthRepository.test();
// NOTA sui settings
//   id è ID della AgencySetup con cui sei dentro
//   agency_id è l'Agency (la Holding) a cui appartiene
const toCustomAttributes = (data) => {
    let ret = [];
    // nella index degli attributes, invia type e class in questa property:
    // type_specification:
    //   {
    //       "type": "text",
    //       "class": "number"
    //   }
    data.forEach((element) => {
        ret.push({
            key: element.code,
            text: element.title,
            type: element.type_specification.type,
            class: element.type_specification.class,
            value: null,
            entity: element.entity.split(/[\\]+/).pop(),
            section: element.section,
            last_delete: element.last_delete,
            last_update: element.last_update,
            is_clientvalid: element.is_clientvalid.value,
            is_customizable: element.is_customizable.value,
            is_mandatory: element.is_mandatory.value,
            is_searchable: element.is_searchable.value,
            is_sortable: element.is_sortable.value,
            is_hidden: element.is_hidden.value,
        });
    });
    return ret;
};
const groupByEntity = (array) => {
    // Group array by key "entity"
    return array.reduce(function (r, a) {
        // set group lowercase
        // a.entity = a.entity.toLowerCase();
        // let entity = a.entity ? a.entity.toLowerCase() : undefined;
        // convert from PascalCase to snake_case
        let entity = a.entity
            ? a.entity
                .split(/(?=[A-Z])/)
                .join("_")
                .toLowerCase()
            : undefined;
        // delete entity from properties and use its value as key in the returned object
        delete a.entity;
        r[entity] = r[entity] || [];
        r[entity].push(a);
        return r;
    }, Object.create(null));
};
// PLEASE KEEP everything ALPHABETICALLY SORTED!
const auth = {
    namespaced: true,
    state: {
        agency_brokers: [],
        bank_accounts: [],
        book_tags: [],
        branches: [],
        cash_references: [],
        cooperators: [],
        config_agency_name: "",
        councils: {},
        counters: {},
        countries: [],
        customAttributes: [], // auth
        insurers: [],
        conti_prima_nota: [],
        mandate_codes: [],
        license: 0,
        lisa_for_apps: [],
        payment_references: [],
        permissions: [], // auth
        products: [],
        provinces: [],
        relative_types: [],
        reports: [],
        risks: [],
        roles: [], // auth
        salesmen: [],
        sectors: [],
        settings: {},
        status: "",
        token: "", // auth
        token_jwt: "", // auth (lisacomunica)
        treasuries: [],
        user: {}, // auth
        users: [],
        use_fee: "N", // global among all insurers: if some value is Y, then Y
    },

    mutations: {
        ATTRIBUTES_SUCCESS(state, {data}) {
            state.status = "success: attributes";
            state.customAttributes = groupByEntity(toCustomAttributes(data));
        },
        ATTRIBUTES_ERROR(state) {
            state.status = "error: attributes";
        },
        AUTH_REQUEST(state) {
            state.status = "loading";
        },
        AUTH_SUCCESS(
            state,
            {
                config_agency_name,
                customAttributes,
                permissions,
                roles,
                settings,
                token,
                token_jwt,
                license,
                user,
            }
        ) {
            state.config_agency_name = config_agency_name;
            state.customAttributes = customAttributes;
            state.license = license;
            state.permissions = permissions;
            state.roles = roles;
            state.settings = settings;
            state.status = "success";
            state.token = token;
            state.token_jwt = token_jwt;
            state.user = user;
        },
        AUTH_ERROR(state) {
            state.status = "error";
        },
        AUTH_LOGOUT(state) {
            // console.debug("mutation AUTH_LOGOUT");
            state.agency_brokers = [];
            state.bank_accounts = [];
            state.book_tags = [];
            state.branches = [];
            state.cooperators = [];
            state.councils = {}; // CHECK: non so ancora come arrivano, ma presumo sarà un oggetto contenete province1: [], province2: []
            state.counters = {};
            state.countries = [];
            state.customAttributes = [];
            state.insurers = [];
            state.conti_prima_nota = [];
            state.license = 0;
            state.config_agency_name = "";
            state.mandate_codes = [];
            state.payment_references = [];
            state.permissions = [];
            state.products = [];
            state.provinces = [];
            state.relative_types = [];
            state.reports = [];
            state.risks = [];
            state.roles = [];
            state.salesmen = [];
            state.sectors = [];
            state.settings = {};
            state.status = "";
            state.token = "";
            state.token_jwt = "";
            state.treasuries = [];
            state.user = {};
            state.users = [];
            // console.debug("LOGGED IN: ", !!state.user);
        },
        AGENCY_BROKERS_SUCCESS(state, {data}) {
            state.status = "success: agency_brokers";
            state.agency_brokers = data;
        },
        AGENCY_BROKERS_ERROR(state) {
            state.status = "error: agency_brokers";
        },
        BANK_ACCOUNTS_SUCCESS(state, {data}) {
            state.status = "success: bank_accounts";
            state.bank_accounts = data;
        },
        BANK_ACCOUNTS_ERROR(state) {
            state.status = "error: bank_accounts";
        },
        BOOK_TAGS_SUCCESS(state, {data}) {
            state.status = "success: book_tags";
            state.book_tags = data;
        },
        BOOK_TAGS_ERROR(state) {
            state.status = "error: book_tags";
        },
        BRANCHES_SUCCESS(state, {data}) {
            state.status = "success: branches";
            state.branches = data;
        },
        BRANCHES_ERROR(state) {
            state.status = "error: branches";
        },
        CASH_REFERENCES_SUCCESS(state, {data}) {
            state.status = "success: cash_references";
            state.cash_references = data;
        },
        CASH_REFERENCES_ERROR(state) {
            state.status = "error: cash_references";
        },
        COOPERATORS_SUCCESS(state, {data}) {
            state.status = "success: cooperators";
            state.cooperators = data;
        },
        COOPERATORS_ERROR(state) {
            state.status = "error: cooperators";
        },
        COUNCILS_SUCCESS(state, {data}) {
            state.status = "success: councils";
            state.councils = data;
        },
        COUNCILS_ERROR(state) {
            state.status = "error: councils";
        },
        COUNTERS_SUCCESS(state, {data, resource}) {
            state.status = "success: counters";
            state.counters[resource] = data;
        },
        COUNTERS_ERROR(state) {
            state.status = "error: counters";
        },
        COUNTRIES_SUCCESS(state, {data}) {
            state.status = "success: countries";
            state.countries = data;
        },
        COUNTRIES_ERROR(state) {
            state.status = "error: countries";
        },
        INSURERS_SUCCESS(state, {data}) {
            state.status = "success: insurers";
            state.insurers = data;
            state.use_fee = data.some((e) => e.use_fee.value === "Y") ? "Y" : "N";
        },
        INSURERS_ERROR(state) {
            state.status = "error: insurers";
        },
        CONTI_PRIMA_NOTA_SUCCESS(state, {data}) {
            state.status = "success: conti prima nota";
            state.conti_prima_nota = data;
        },
        CONTI_PRIMA_NOTA_ERROR(state) {
            state.status = "error: conti prima nota";
        },
        LISA_FOR_APPS_SUCCESS(state, {data}) {
            state.status = "success: lisa_for_apps";
            state.lisa_for_apps = data;
        },
        LISA_FOR_APPS_ERROR(state) {
            state.status = "error: lisa_for_apps";
        },
        MANDATE_CODES_SUCCESS(state, {data}) {
            state.status = "success: mandate_codes";
            state.mandate_codes = data;
        },
        MANDATE_CODES_ERROR(state) {
            state.status = "error: mandate_codes";
        },
        PAYMENT_REFERENCES_SUCCESS(state, {data}) {
            state.status = "success: payment_references";
            state.payment_references = data;
        },
        PAYMENT_REFERENCES_ERROR(state) {
            state.status = "error: payment_references";
        },
        PRODUCTS_SUCCESS(state, {data}) {
            state.status = "success: products";
            state.products = data;
        },
        PRODUCTS_ERROR(state) {
            state.status = "error: products";
        },
        PROVINCES_SUCCESS(state, {data}) {
            state.status = "success: provinces";
            state.provinces = data;
        },
        PROVINCES_ERROR(state) {
            state.status = "error: provinces";
        },
        RELATIVE_TYPES_SUCCESS(state, {data}) {
            state.status = "success: relative_types";
            state.relative_types = data;
        },
        RELATIVE_TYPES_ERROR(state) {
            state.status = "error: relative_types";
        },
        REPORTS_SUCCESS(state, {data}) {
            state.status = "success: reports";
            state.reports = data;
        },
        REPORTS_ERROR(state) {
            state.status = "error: reports";
        },
        RISKS_SUCCESS(state, {data}) {
            state.status = "success: risks";
            state.risks = data;
        },
        RISKS_ERROR(state) {
            state.status = "error: risks";
        },
        SALESMEN_SUCCESS(state, {data}) {
            state.status = "success: salesmen";
            state.salesmen = data;
        },
        SALESMEN_ERROR(state) {
            state.status = "error: salesmen";
        },
        SECTORS_SUCCESS(state, {data}) {
            state.status = "success: sectors";
            state.sectors = data;
        },
        SECTORS_ERROR(state) {
            state.status = "error: sectors";
        },
        SETTING_SAVE(state, {name, value}) {
            state.settings[name] = value;
            state.status = "saved";
        },
        TREASURIES_SUCCESS(state, {data}) {
            state.status = "success: treasuries";
            state.treasuries = data;
        },
        TREASURIES_ERROR(state) {
            state.status = "error: treasuries";
        },
        USERS_SUCCESS(state, {data}) {
            state.status = "success: users";
            state.users = data;
        },
        USERS_ERROR(state) {
            state.status = "error: users";
        },
    },

    actions: {
        agency_brokers({commit}) {
            return new Promise((resolve, reject) => {
                let IndexRepository = RepositoryFactory.get("index");
                IndexRepository.agency_brokers()
                    .then((response) => {
                        const data = response.data.data;
                        commit("AGENCY_BROKERS_SUCCESS", {
                            data,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        commit("AGENCY_BROKERS_ERROR");
                        reject(error);
                    });
            });
        },
        attributes({commit}) {
            return new Promise((resolve, reject) => {
                let IndexRepository = RepositoryFactory.get("index");
                IndexRepository.attributes()
                    .then((response) => {
                        const data = response.data.data;
                        commit("ATTRIBUTES_SUCCESS", {
                            data,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        commit("ATTRIBUTES_ERROR");
                        reject(error);
                    });
            });
        },
        bank_accounts({commit}) {
            return new Promise((resolve, reject) => {
                let IndexRepository = RepositoryFactory.get("index");
                IndexRepository.bank_accounts()
                    .then((response) => {
                        const data = response.data.data;
                        commit("BANK_ACCOUNTS_SUCCESS", {
                            data,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        commit("BANK_ACCOUNTS_ERROR");
                        reject(error);
                    });
            });
        },
        book_tags({commit}) {
            return new Promise((resolve, reject) => {
                let IndexRepository = RepositoryFactory.get("index");
                IndexRepository.book_tags()
                    .then((response) => {
                        const data = response.data.data;
                        commit("BOOK_TAGS_SUCCESS", {
                            data,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        commit("BOOK_TAGS_ERROR");
                        reject(error);
                    });
            });
        },
        branches({commit}) {
            return new Promise((resolve, reject) => {
                let IndexRepository = RepositoryFactory.get("index");
                IndexRepository.branches()
                    .then((response) => {
                        const data = response.data.data;
                        commit("BRANCHES_SUCCESS", {
                            data,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        commit("BRANCHES_ERROR");
                        reject(error);
                    });
            });
        },
        cash_references({commit}) {
            return new Promise((resolve, reject) => {
                let IndexRepository = RepositoryFactory.get("index");
                IndexRepository.cash_references()
                    .then((response) => {
                        const data = response.data;
                        commit("CASH_REFERENCES_SUCCESS", {
                            data,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        commit("CASH_REFERENCES_ERROR");
                        reject(error);
                    });
            });
        },
        conti_prima_nota({commit}) {
            return new Promise((resolve, reject) => {
                let IndexRepository = RepositoryFactory.get("index");
                IndexRepository.conti_prima_nota()
                    .then((response) => {
                        const data = response.data.data;
                        commit("CONTI_PRIMA_NOTA_SUCCESS", {
                            data,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        commit("CONTI_PRIMA_NOTA_ERROR");
                        reject(error);
                    });
            });
        },
        cooperators({commit}) {
            return new Promise((resolve, reject) => {
                let IndexRepository = RepositoryFactory.get("index");
                IndexRepository.cooperators()
                    .then((response) => {
                        const data = response.data.data;
                        commit("COOPERATORS_SUCCESS", {
                            data,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        commit("COOPERATORS_ERROR");
                        reject(error);
                    });
            });
        },

        councils({commit}) {
            return new Promise((resolve, reject) => {
                let IndexRepository = RepositoryFactory.get("index");
                IndexRepository.councils()
                    .then((response) => {
                        const data = response.data;
                        commit("COUNCILS_SUCCESS", {
                            data,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        commit("COUNCILS_ERROR");
                        reject(error);
                    });
            });
        },
        counters({commit}, {resource, query}) {
            return new Promise((resolve, reject) => {
                let IndexRepository = RepositoryFactory.get("index");
                IndexRepository.counters(resource, query)
                    .then((response) => {
                        const data = response.data.data;
                        commit("COUNTERS_SUCCESS", {
                            data: data.count,
                            resource,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        commit("COUNTERS_ERROR");
                        reject(error);
                    });
            });
        },
        /**
         *
         {
         alpha2: "AF"
         alpha3: "AFG"
         formatted_title: "Afghanistan"
         id: 1
         last_update: "2022-04-20 14:17:48"
         provinces: []
         title: "Afghanistan"
         }
         */
        countries({commit}) {
            return new Promise((resolve, reject) => {
                let IndexRepository = RepositoryFactory.get("index");
                IndexRepository.countries()
                    .then((response) => {
                        const data = response.data.data;
                        commit("COUNTRIES_SUCCESS", {
                            data,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        commit("COUNTRIES_ERROR");
                        reject(error);
                    });
            });
        },
        insurers({commit}) {
            return new Promise((resolve, reject) => {
                let IndexRepository = RepositoryFactory.get("index");
                IndexRepository.insurers()
                    .then((response) => {
                        const data = response.data.data;
                        commit("INSURERS_SUCCESS", {
                            data,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        commit("INSURERS_ERROR");
                        reject(error);
                    });
            });
        },
        lisa_for_apps({commit}) {
            return new Promise((resolve, reject) => {
                let IndexRepository = RepositoryFactory.get("index");
                // auth.state.user.id
                IndexRepository.lisa_for_apps(auth.state.user.id)
                    .then((response) => {
                        const data = response.data.data;
                        commit("LISA_FOR_APPS_SUCCESS", {
                            data,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        commit("LISA_FOR_APPS_ERROR");
                        reject(error);
                    });
            });
        },
        login({commit}, credentials) {
            return new Promise((resolve, reject) => {
                commit("AUTH_REQUEST");
                // let okta_token;
                // credentials.options = {
                //   multiOptionalFactorEnroll: false,
                //   warnBeforePasswordExpired: false,
                // };
                // AuthRepository.okta(credentials)
                //   .then((response) => {
                //     let status = response.status;
                //     //   "status": "SUCCESS"
                //     //   "status": "PASSWORD_EXPIRED"
                //     if (status === "PASSWORD_EXPIRED") {
                //       reject("password expired");
                //     }
                //     okta_token = response.token;
                let AuthRepository = RepositoryFactory.get("auth");
                AuthRepository.csrf().then(() => {
                    //   AuthRepository.login(okta_token);
                    AuthRepository.login(credentials)
                        .then((response) => {
                            console.info("User signed in!");
                            const permissions = response.data.acl.permissions;
                            const roles = response.data.acl.roles;
                            const settings = response.data.setting;
                            const token = response.data.token;
                            const token_jwt = response.data.token_jwt;
                            const user = response.data.user;
                            const license = response.data.agency.license;
                            const config_agency_name = response?.config?.headers?.Agenzia;
                            let tempAttributes = response.data.customAttributes;
                            /* NEW structure... again...
                            {
                              "key": "CUST1",
                              "text": "Personale 1",
                              "value": null,
                              "entity": "Registry",
                              "section": "CUST",
                              "is_clientvalid": "N",
                              "is_customizable": "Y",
                              "is_mandatory": "N",
                              "is_searchable": "Y",
                              "is_sortable": "Y"
                            }, */

                            // Group tempAttributes by key "entity"
                            // const customAttributes = groupByEntity(tempAttributes);
                            const customAttributes = groupByEntity(
                                toCustomAttributes(tempAttributes)
                            );

                            // FUTURE get dictionary and dispatch store dictionaries set action
                            // const dictionaries = response.data.agency.dictionaries;
                            // // move this on top!!
                            // import store from "@/store";
                            // store.dispatch("dictionaries/set", dictionaries);

                            console.debug(
                                `customAttributes: ${JSON.stringify(customAttributes)}`
                            );
                            console.debug(`permissions: ${JSON.stringify(permissions)}`);
                            console.debug(`roles: ${JSON.stringify(roles)}`);
                            console.debug(`settings: ${JSON.stringify(settings)}`);
                            console.debug(`token: ${token}`);
                            console.debug(`token_jwt: ${token_jwt}`);
                            console.debug(`user: ${JSON.stringify(user)}`);
                            console.debug(`license: ${license}`);
                            console.debug(`config_agency_name: ${config_agency_name}`);

                            Vue.prototype.$axios.defaults.headers.common.Authorization = `Bearer ${token}`;
                            commit("AUTH_SUCCESS", {
                                customAttributes,
                                config_agency_name,
                                license,
                                permissions,
                                roles,
                                settings,
                                token,
                                token_jwt,
                                user,
                            });
                            resolve(response);
                        })
                        .catch((error) => {
                            commit("AUTH_ERROR");
                            reject(error);
                        }); // login
                }); // csrf
                //   })
                //   .catch((error) => {
                //     commit("AUTH_ERROR");
                //     reject(error);
                //   }); // okta
            });
        },
        logout({commit}) {
            return new Promise((resolve, reject) => {
                let AuthRepository = RepositoryFactory.get("auth");
                AuthRepository.logout()
                    .then(() => {
                        commit("AUTH_LOGOUT");
                        delete Vue.prototype.$axios.defaults.headers.common.Authorization;
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        mandate_codes({commit}) {
            return new Promise((resolve, reject) => {
                let IndexRepository = RepositoryFactory.get("index");
                IndexRepository.mandate_codes()
                    .then((response) => {
                        const data = response.data.data;
                        commit("MANDATE_CODES_SUCCESS", {
                            data,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        commit("MANDATE_CODES_ERROR");
                        reject(error);
                    });
            });
        },
        payment_references({commit}) {
            return new Promise((resolve, reject) => {
                let IndexRepository = RepositoryFactory.get("index");
                IndexRepository.payment_references()
                    .then((response) => {
                        const data = response.data.data;
                        commit("PAYMENT_REFERENCES_SUCCESS", {
                            data,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        commit("PAYMENT_REFERENCES_ERROR");
                        reject(error);
                    });
            });
        },
        products({commit}) {
            return new Promise((resolve, reject) => {
                let IndexRepository = RepositoryFactory.get("index");
                IndexRepository.products()
                    .then((response) => {
                        const data = response.data.data;
                        commit("PRODUCTS_SUCCESS", {
                            data,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        commit("PRODUCTS_ERROR");
                        reject(error);
                    });
            });
        },
        provinces({commit}) {
            return new Promise((resolve, reject) => {
                let IndexRepository = RepositoryFactory.get("index");
                IndexRepository.provinces()
                    .then((response) => {
                        const data = response.data.data;
                        commit("PROVINCES_SUCCESS", {
                            data,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        commit("PROVINCES_ERROR");
                        reject(error);
                    });
            });
        },
        relative_types({commit}) {
            return new Promise((resolve, reject) => {
                let IndexRepository = RepositoryFactory.get("index");
                IndexRepository.relative_types()
                    .then((response) => {
                        const data = response.data.data;
                        commit("RELATIVE_TYPES_SUCCESS", {
                            data,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        commit("RELATIVE_TYPES_ERROR");
                        reject(error);
                    });
            });
        },
        reports({commit}) {
            return new Promise((resolve, reject) => {
                let IndexRepository = RepositoryFactory.get("index");
                IndexRepository.reports()
                    .then((response) => {
                        const data = response.data.data;
                        commit("REPORTS_SUCCESS", {
                            data,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        commit("REPORTS_ERROR");
                        reject(error);
                    });
            });
        },
        risks({commit}) {
            return new Promise((resolve, reject) => {
                let IndexRepository = RepositoryFactory.get("index");
                IndexRepository.risks()
                    .then((response) => {
                        const data = response.data.data;
                        commit("RISKS_SUCCESS", {
                            data,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        commit("RISKS_ERROR");
                        reject(error);
                    });
            });
        },
        salesmen({commit}) {
            return new Promise((resolve, reject) => {
                let IndexRepository = RepositoryFactory.get("index");
                IndexRepository.salesmen()
                    .then((response) => {
                        const data = response.data.data;
                        commit("SALESMEN_SUCCESS", {
                            data,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        commit("SALESMEN_ERROR");
                        reject(error);
                    });
            });
        },
        sectors({commit}) {
            return new Promise((resolve, reject) => {
                let IndexRepository = RepositoryFactory.get("index");
                IndexRepository.sectors()
                    .then((response) => {
                        const data = response.data.data;
                        commit("SECTORS_SUCCESS", {
                            data,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        commit("SECTORS_ERROR");
                        reject(error);
                    });
            });
        },
        treasuries({commit}) {
            return new Promise((resolve, reject) => {
                let IndexRepository = RepositoryFactory.get("index");
                IndexRepository.treasuries()
                    .then((response) => {
                        const data = response.data.data;
                        commit("TREASURIES_SUCCESS", {
                            data,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        commit("TREASURIES_ERROR");
                        reject(error);
                    });
            });
        },
        users({commit}) {
            return new Promise((resolve, reject) => {
                let IndexRepository = RepositoryFactory.get("index");
                IndexRepository.users()
                    .then((response) => {
                        const data = response.data.data;
                        commit("USERS_SUCCESS", {
                            data,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        commit("USERS_ERROR");
                        reject(error);
                    });
            });
        },
    },

    getters: {
        agency_brokers: (state) => {
            // console.info("auth getter agency_brokers");
            return state.agency_brokers.map((e) => {
                // ora i collaboratori arrivano come broker, e la novità è che possono essere
                // di tipo fisico o giuridico, e nei rispettivi casi non arrivano gli attributi relativi all'altro caso
                return {
                    value: e.id,
                    text:
                        `${e.attributables.SURN ? e.attributables.SURN : ""} ${
                            e.attributables.NAME ? e.attributables.NAME : ""
                        }`.trim() ||
                        `${e.attributables.CNAM ? e.attributables.CNAM : ""} ${
                            e.attributables.CREG ? e.attributables.CREG : ""
                        }`.trim() ||
                        `${e.formatted_title}`.trim(),
                };
            });
        },
        authStatus: (state) => state.status, // auth
        bank_accounts: (state) => {
            // console.info("auth getter bank_accounts");
            return state.bank_accounts.map((bank_account) => {
                return {
                    value: bank_account.id,
                    text: `${bank_account.formatted_title}`,
                    referer_type: `${bank_account.referer_type.value}`,
                };
            });
        },
        book_tags: (state) => {
            // console.info("auth getter book_tags");
            return state.book_tags
                .map((book_tag) => {
                    return {
                        value: book_tag.id,
                        text: `${book_tag.code} - ${book_tag.title}`,
                        is_system: book_tag.is_system.value,
                    };
                })
                .sort((a, b) => (a.text > b.text ? 1 : -1));
        },
        /**
         *
         * @param {*} insurer_ids (devo filtrare la lista dei RISCHI ed estrarre gli id dei rami, poi filtrare la lista dei RAMi con quegli id )
         * @returns array of options value/text
         */
        branches:
            (state) =>
                (insurer_ids = []) => {
                    // console.info("auth getter branches");
                    if (!Array.isArray(insurer_ids)) {
                        if (insurer_ids) {
                            insurer_ids = [insurer_ids];
                        } else {
                            insurer_ids = [];
                        }
                    }
                    if (insurer_ids.length > 0) {
                        // filter branches associated with insurer_id:
                        // 1. extract from risks a list of branch ids for the given insurer
                        let branch_ids = state.products
                            .filter((product) =>
                                insurer_ids.length
                                    ? insurer_ids.includes(product.insurer_id)
                                    : true
                            )
                            .map((product) => product.risk_branch_id);
                        // .filter((v, i, a) => a.indexOf(v) == i); // get unique
                        // 2. filter branches with the extracted ids

                        return state.branches
                            .filter((branch) => branch_ids.includes(branch.id))
                            .map((branch) => {
                                return {
                                    value: branch.id,
                                    text: `${branch.code} - ${branch.title}`,
                                };
                            });
                        // let branches = state.branches.filter((branch) =>
                        //   branch_ids.includes(branch.id)
                        // );
                        // // branches = [...new Set(branches.map((item) => item.id))];
                        // return branches.map((branch) => {
                        //   return { value: branch.id, text: branch.title };
                        // });
                    } else {
                        // no filter, return all
                        return state.branches.map((branch) => {
                            return {
                                value: branch.id,
                                text: `${branch.code} - ${branch.title}`,
                            };
                        });
                    }
                },
        cash_references: (state) => {
            // console.info("auth getter cash_references");
            return state.cash_references.map((cash_reference) => {
                return {
                    value: cash_reference.id,
                    text: `${cash_reference.codice}`,
                    description: `${cash_reference.descrizione}`,
                    has_broker: cash_reference.include_produttore,
                    has_company: cash_reference.include_compagnia,
                    entry_type: cash_reference.tipo_di_movimento,
                    reference_type: cash_reference.tipologia,
                    cash_reference_id: cash_reference.causale_collegata,
                    linked_cash_reference: cash_reference.causale_collegata,
                    treasury_id: cash_reference.id_conto_prima_nota_default,
                };
            });
        },
        conti_prima_nota: (state) => {
            // console.info("auth getter conti_prima_nota");
            return state.conti_prima_nota.map((conto) => {
                return conto;
            });
        },
        cooperators: (state) => {
            // console.info("auth getter cooperators");
            return state.cooperators.map((e) => {
                // ora i collaboratori arrivano come broker, e la novità è che possono essere
                // di tipo fisico o giuridico, e nei rispettivi casi non arrivano gli attributi relativi all'altro caso
                return {
                    value: e.id,
                    text:
                        `${e.attributables.SURN ? e.attributables.SURN : ""} ${
                            e.attributables.NAME ? e.attributables.NAME : ""
                        }`.trim() ||
                        `${e.attributables.CNAM ? e.attributables.CNAM : ""} ${
                            e.attributables.CREG ? e.attributables.CREG : ""
                        }`.trim() ||
                        `${e.formatted_title}`.trim(),
                };
            });
        },
        /**
         *
         {
         "id": 1,
         "title": "Cesana Torinese",
         "code": "001074",
         "registry": "C564",
         "postcode": "10054",
         "province_id": 1,
         "formatted_title": "Cesana Torinese",
         "last_update": "2022-04-20 14:17:48"
         }
         */
        councils:
            (state) =>
                (province_id, council_partial_name, include_dismissed = false) => {
                    // console.info("auth getter councils", province_id, council_partial_name);
                    return state.councils
                        .filter((council) => {
                            return province_id ? council.province_id == province_id : true;
                        })
                        .filter((council) => {
                            return !include_dismissed ? council.is_dismissed == "N" : true;
                        })
                        .filter((council) => {
                            return council_partial_name
                                ? council.title
                                    .toLowerCase()
                                    .startsWith(council_partial_name.toLowerCase())
                                : true;
                        })
                        .map((council) => {
                            return {
                                value: council.id,
                                text: council.title,
                                province: council.province_id,
                                postcode: council.postcode,
                                code: council.code,
                                is_dismissed: council.is_dismissed,
                            };
                        })
                        .sort((a, b) => (a.text > b.text ? 1 : -1));
                },
        countries: (state) => {
            // console.info("auth getter countries");
            return state.countries.map((country) => {
                return {value: country.id, text: country.title};
            });
        },
        countryByCouncil:
            (state) =>
                (council_id, field = "title") => {
                    let ret;
                    let council;
                    let country;
                    let province;
                    let province_id;
                    council = state.councils.find((e) => e.id == council_id);
                    if (!council) {
                        // non dovrebbe MAI accadere
                        ret = {title: "Nazione non trovata", code: "N/A"}[field];
                        return ret;
                    }
                    province_id = council.province_id;

                    if (!province_id) {
                        if (council.registry === "ZZ") {
                            ret = {title: "Nazione estera", code: "ZZ"}[field];
                        } else {
                            ret = {title: "Italia", code: "IT"}[field];
                        }
                        return ret;
                    }

                    province = state.provinces.find((e) => e.id == province_id);
                    if (!province) {
                        ret = {title: "Nazione estera", code: "ZZ"}[field];
                        return ret;
                    }

                    country = state.countries.find((e) => e.id == province.country_id);
                    if (!country) {
                        ret = {title: "Nazione estera", code: "ZZ"}[field];
                        return ret;
                    }
                    return country[field];
                },
        countryByProvince:
            (state) =>
                (province_id, field = "title") => {
                    let ret;
                    let country_id;
                    let province = state.provinces.find(
                        (province) => province.id == province_id
                    );
                    if (!province) {
                        ret = {title: "Nazione estera", code: "ZZ"}[field];
                        return ret;
                    }
                    country_id = province.country_id;
                    // NOTE "Provincia estera" has null country_id
                    // {
                    //     "id": 108,
                    //     "title": "Provincia estera",
                    //     "code": "ZZ",
                    //     "region_id": null,
                    //     "country_id": null
                    // }
                    if (!country_id) {
                        // WARNING: this cannot work!!!
                        ret = {title: "Nazione estera", code: "ZZ"}[field];
                        return ret;
                    }
                    return state.countries.find((country) => country.id == country_id)[
                        field
                        ];
                },
        customAttributes: (state) => (namespace) => {
            // auth
            // namespace is a parameter for the getter
            return state.customAttributes[namespace];
        },
        insurers: (state) => {
            // console.info("auth getter insurers");
            return state.insurers.map((insurer) => {
                // CHECK return value as insurer.id or insurer.code?
                return {
                    value: insurer.id,
                    text: `${insurer.code} - ${insurer.title}`,
                    importer_id: insurer.importer_id,
                    use_fee: insurer.use_fee.value,
                };
            });
        },
        insurerIdByProduct: (state) => (product_id) => {
            // console.info("auth getter insurerIdByProduct");
            return state.products.find((product) => product.id === product_id)
                .insurer_id;
        },
        isLogged: (state) => !!state.token, // auth
        lisa_for_apps: (state) => {
            // console.info("auth getter lisa_for_apps");
            let myMap = new Map();
            state.lisa_for_apps.forEach((obj) =>
                myMap.set(obj.code, obj.users.length ? obj.users[0].pivot : {})
            );
            // console.log(`myMap: ${Object.fromEntries(myMap)}`);
            return Object.fromEntries(myMap);
        },
        mandate_codes: (state) => (insurer_id) => {
            // console.info("auth getter mandate_codes");
            if (insurer_id) {
                return state.mandate_codes
                    .filter(
                        (mandate) =>
                            mandate.is_expired.value === "N" &&
                            mandate.insurer_id === insurer_id
                    )
                    .map((mandate) => {
                        return {
                            value: mandate.id,
                            text: `${mandate.code} - ${mandate.title}`,
                            code: `${mandate.code}`,
                        };
                    });
            } else {
                // no filter, return all
                return state.mandate_codes.map((mandate) => {
                    return {
                        value: mandate.id,
                        text: `${mandate.code} - ${mandate.title}`,
                        code: `${mandate.code}`,
                    };
                });
            }
        },
        payment_references: (state) => {
            // console.info("auth getter payment_references");
            return state.payment_references.map((payment_reference) => {
                return {
                    value: payment_reference.id,
                    text: `${payment_reference.code}-${payment_reference.caption}`,
                };
            });
        },
        permissions: (state) => state.permissions, // auth
        roles: (state) => state.roles, // auth
        /**
         *
         * @param Array insurer_ids (per ora arriverà sempre SOLO 1 intero, NON 1 Array, ma non si sa mai...)
         * @param Array risk_branch_id
         * @returns array of options value/text
         */
        products:
            (state) =>
                (insurer_ids = [], risk_branch_ids = [], risk_ids = []) => {
                    // console.info("auth getter products");
                    // need to filter risks by array of insurer and/or branch ids
                    if (!Array.isArray(insurer_ids)) {
                        if (insurer_ids) {
                            insurer_ids = [insurer_ids];
                        } else {
                            insurer_ids = [];
                        }
                    }
                    if (!Array.isArray(risk_branch_ids)) {
                        if (risk_branch_ids) {
                            risk_branch_ids = [risk_branch_ids];
                        } else {
                            risk_branch_ids = [];
                        }
                    }
                    if (!Array.isArray(risk_ids)) {
                        if (risk_ids) {
                            risk_ids = [risk_ids];
                        } else {
                            risk_ids = [];
                        }
                    }
                    return state.products
                        .filter(
                            (product) =>
                                (insurer_ids.length
                                    ? insurer_ids.includes(product.insurer_id)
                                    : true) &&
                                (risk_branch_ids.length
                                    ? risk_branch_ids.includes(product.risk_branch_id)
                                    : true) &&
                                (risk_ids.length
                                    ? risk_ids.includes(product.risk_branch.risk_type_id)
                                    : true)
                        )
                        .map((product) => {
                            return {
                                value: product.id,
                                text: product.code + " - " + product.title,
                                risk_branch_id: product.risk_branch_id,
                                delay_days: product.delay_days,
                            };
                        })
                        .sort((a, b) => (a.text > b.text ? 1 : -1));
                },
        provinceByProvinceId: (state) => (province_id) => {
            return state.provinces
                .filter((province) => province.id === province_id)
                .map((province) => {
                    return {province_name: province.title};
                });
        },
        /**
         *
         {
         id: 1
         code: "TO"
         councils: [,…]
         country_id: 88
         formatted_title: "Torino"
         last_update: "2022-04-20 14:17:48"
         region_id: 1
         title: "Torino"
         }
         */
        provinces: (state) => (country_id) => {
            // console.info("auth getter provinces", country_id);
            /* NOTE: country_id null is:
            {
                  "id": 108,
                  "title": "Provincia estera",
                  "code": "ZZ",
                  "region_id": null,
                  "country_id": null
            } */
            return state.provinces
                .filter((province) => province.country_id == country_id)
                .map((province) => {
                    return {value: province.id, text: province.title};
                })
                .sort((a, b) => (a.text > b.text ? 1 : -1));
        },
        provinceByCouncil:
            (state) =>
                (council_id, field = "title") => {
                    let ret;
                    let province_id;
                    let council = state.councils.find(
                        (council) => council.id == council_id
                    );
                    // NOTE "Comune estero (ZZ)" has null province_id
                    // {
                    //       "id": 7905,
                    //       "title": "Comune estero",
                    //       "code": "ZZ",
                    //       "registry": null,
                    //       "postcode": null,
                    //       "province_id": null
                    //   }
                    if (!council) {
                        // non dovrebbe MAI accadere
                        ret = {title: "Provincia non trovata", code: "N/A"}[field];
                        return ret;
                    }
                    province_id = council.province_id;
                    if (!province_id) {
                        ret = {
                            title:
                                council.registry == "ZZ"
                                    ? "Provincia estera"
                                    : "Provincia inesistente",
                            code: null,
                        }[field];
                        return ret;
                    }
                    return state.provinces.find((province) => province.id == province_id)[
                        field
                        ];
                },
        relative_types: (state) => {
            // console.info("auth getter relative_types");
            return state.relative_types.map((relative_type) => {
                return {
                    value: relative_type.id,
                    female: relative_type.female,
                    male: relative_type.male,
                    multi: relative_type.is_multi,
                    is_unique: relative_type.is_unique,
                };
            });
        },
        report: (state) => (report_code) => {
            let r = state.reports.find((e) => e.code === report_code);
            if (!r) {
                return null;
            }
            let {users, ...rest} = r;
            let user = users.find((e) => e.id == state.user.id);
            return {...rest, users: [user]};
            // for (const user of r.users) {
            //   if (user.id == state.user.id) {
            //     // logged user
            //     let { users, ...rest } = user;
            //     return { ...rest, users: [user] };
            //   }
            // }
        },
        // reports: (state) => (user_id) => {
        //   console.info("auth getter reports");
        //   if (user_id) {
        //     return state.reports
        //       .filter(
        //         (mandate) =>
        //           mandate.is_expired.value === "N" &&
        //           mandate.insurer_id === insurer_id
        //       )
        //       .map((mandate) => {
        //         return {
        //           value: mandate.id,
        //           text: `${mandate.code} - ${mandate.title}`,
        //           code: `${mandate.code}`,
        //         };
        //       });
        //   } else {
        //     // filter by logged user
        //     return state.reports.map((mandate) => {
        //       return {
        //         value: mandate.id,
        //         text: `${mandate.code} - ${mandate.title}`,
        //         code: `${mandate.code}`,
        //       };
        //     });
        //   }
        // },
        risks: (state) => {
            return state.risks.map((risk) => {
                // CHECK return value as insurer.id or insurer.code?
                return {
                    value: risk.id,
                    text: `${risk.code} - ${risk.title}`,
                    code: `${risk.code}`,
                };
            });
        },
        salesmen: (state) => {
            // console.info("auth getter salesmen");
            return state.salesmen
                .map((e) => {
                    // return { value: salesman.id, text: salesman.title };
                    // ora i produttori arrivano come broker, e la novità è che possono essere
                    // di tipo fisico o giuridico, e nei rispettivi casi non arrivano gli attributi relativi all'altro caso
                    return {
                        value: e.id,
                        text:
                            (e.attributables.NAME
                                ? `${e.attributables.BUID ? e.attributables.BUID : ""} - ${
                                    e.attributables.SURN ? e.attributables.SURN : ""
                                } ${e.attributables.NAME ? e.attributables.NAME : ""}`
                                : `${e.attributables.BUID ? e.attributables.BUID : ""} - ${
                                    e.attributables.CNAM ? e.attributables.CNAM : ""
                                } ${e.attributables.CREG ? e.attributables.CREG : ""}`) ||
                            `${e.formatted_title}`,
                    };
                })
                .sort((a, b) => {
                    // Split by the first occurrence of " - "
                    const splitA = a.text.split(" - ");
                    const splitB = b.text.split(" - ");

                    // Use the part after the first " - " if it exists, otherwise the whole text
                    const textA = splitA.length > 1 ? splitA[1] : a.text;
                    const textB = splitB.length > 1 ? splitB[1] : b.text;

                    // Compare alphabetically
                    return textA.localeCompare(textB);
                });
        },
        sectors: (state) => {
            // console.info("auth getter sectors");
            return state.sectors.map((sector) => {
                return {
                    value: sector.id,
                    text: `${sector.display} - ${sector.title}`,
                    code: `${sector.code}`,
                    is_abstract: sector.is_abstract.value,
                    is_system: sector.is_system.value,
                    is_agency: sector.is_agency.value,
                };
            });
        },
        setting: (state) => (name) => {
            //name is a parameter for the getter
            // console.debug("auth getter setting", name);
            return state.settings[name];
        },
        settings: (state) => {
            // console.debug("auth getter settings");
            return state.settings;
        },
        allTreasuries: (state) => {
            return state.treasuries.map((treasury) => {
                return {
                    value: treasury.id,
                    code: treasury.code,
                    text: `${treasury.display} - ${treasury.title}`,
                    is_sundry: treasury.is_sundry_expense,
                    sector: treasury.sector,
                };
            });
        },
        allContiPrimaNota: (state) => {
            return state.conti_prima_nota.map((conto) => {
                return {
                    value: conto.id,
                    code: conto.codice,
                    text: `${conto.codice} - ${conto.descrizione}`,
                };
            });
        },
        excludedTreasuries:
            (state) =>
                (context = "OTHER", field = "is_sundry_expense", excludeWhen = "Y") => {
                    // #1022
                    let ret = [];
                    // per il momento non sembra ci sia da differenziare chi non è 'other'...
                    // switch (context) {
                    //   case "PFOLIO":
                    //     break;
                    //   default: // OTHER
                    //     // Se la forma di pagamento è in Y, non si deve vedere in tutte le altre tendine possibili immaginabili
                    //     ret = state.treasuries
                    //       .filter((e) => e.nuovoFlag == "Y")
                    //       .map((e) => e.code);
                    //     break;
                    // }
                    // ... quindi basta un singolo check
                    if (context === "OTHER") {
                        ret = state.treasuries
                            .filter((e) => e[field]?.value === excludeWhen)
                            .map((e) => e.code);
                    }
                    return ret;
                },
        treasuries:
            (state) =>
                (excludeCodes = ["AC"]) => {
                    // USAGE:
                    // treasuries()() per escludere il default AC
                    // treasuries()(['CC', 'AC']) per escludere CC e AC
                    // treasuries()([]) per non escludere nulla
                    // console.info("auth getter treasuries", state.treasuries);
                    return state.treasuries
                        .filter((treasury) => treasury.sector.is_abstract.value === "N") // custom filter: only is_abstract.value === 'N'
                        .filter((treasury) => {
                            return excludeCodes.length
                                ? !excludeCodes.includes(treasury.code)
                                : true;
                        })
                        .map((treasury) => {
                            return {
                                value: treasury.id,
                                text: `${treasury.display} - ${treasury.title}`,
                                code: `${treasury.code}`,
                            };
                        });
                },
        users: (state) => {
            // console.info("auth getter users");
            return state.users.map((user) => {
                return {value: user.id, text: user.email, username: user.username};
            });
        },
        user: (state) => {
            return state.user;
        },
    },
};
export default auth;
