import Repository from "@/repositories/Repository";

// Verb 	    URI 	                Action 	  Route Name
// GET 	      /photos 	            index 	  photos.index
// GET 	      /photos/create 	      create 	  photos.create
// POST 	    /photos 	            store 	  photos.store
// GET 	      /photos/{photo} 	    show 	    photos.show
// GET 	      /photos/{photo}/edit 	edit 	    photos.edit
// PUT/PATCH 	/photos/{photo} 	    update 	  photos.update
// DELETE 	  /photos/{photo} 	    destroy 	photos.destroy

const resource = "/fattutto";
export default {
  create_configuration() {
    // get the create form
    return Repository.get(`${resource}/configuration/create`);
  },
  edit_configuration(id) {
    // get the edit form
    return Repository.get(`${resource}/configuration/${id}/update`);
  },
  show_configuration(id, relationString) {
    return relationString
      ? Repository.get(`${resource}/configuration/${id}/${relationString}`)
      : Repository.get(`${resource}/configuration/${id}`);
  },
  store_configuration(payload) {
    // save the item
    return Repository.post(`${resource}/configuration`, payload);
  },
  update_configuration(id, payload) {
    // update the item
    return Repository.put(`${resource}/configuration/${id}`, payload);
  },
  index_configuration(queryString) {
    // get the list
    return queryString
      ? Repository.get(`${resource}/configuration?${queryString}`)
      : Repository.get(`${resource}/configuration`);
  },
  index_transmission(queryString) {
    // get the list
    return queryString
      ? Repository.get(`${resource}/transmission?${queryString}`)
      : Repository.get(`${resource}/transmission`);
  },
  create_transmission(payload) {
    // create the transmission
    return Repository.post(`${resource}/transmission`, payload);
  },
  send_transmission(payload) {
    // send the transmission
    return Repository.post(`${resource}/transmission`, payload);
  },
  retry_transmission(id) {
    // retry the transmission
    return Repository.post(`${resource}/transmission/${id}/retry`);
  },
  show_transmission(id) {
    // get the details
    return Repository.get(`${resource}/transmission/${id}`);
  },
};
