<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <title>Chiudi</title>
    <path
      d="M16 1.8L14.2 0 8 6.2 1.8 0 0 1.8 6.2 8 0 14.2 1.8 16 8 9.8l6.2 6.2 1.8-1.8L9.8 8z"
      fill="#333"
    />
  </svg>
</template>

<script>
export default {};
</script>
