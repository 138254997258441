<template>
  <div
    style="
      position: sticky;
      top: 0;
      z-index: 3;
      border-bottom: 1px solid #cdc5c0;
    "
  >
    <navbar
      :notifications="notifications"
      :loadingNotifications="loadingNotifications"
      :pTitle="pageTitle"
      v-if="isLogged && !$store.state.sidebar.locked"
      @signoff="logout"
    />
    <div
      class="wrapper"
      v-if="isLogged && !$store.state.sidebar.locked"
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #e1ddda;
        padding: 0.3rem 1.25rem 0.3rem 3em;
      "
    >
      <breadcrumbs :bcrumbs="clearedBreadcrumbList" />
      <div
        class="version text-sm-right"
        style="white-space: nowrap; font-size: 10px"
      >
        Agenzia: <b>{{ agencyName }}</b>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    Navbar,
    Breadcrumbs,
  },
  name: "CustomHeader",
  data() {
    return {
      breadcrumblist: null,
      clearedBreadcrumbList: null,
      pageTitle: null,
      something: "",
      isLoading: false,
      results: {},
    };
  },
  props: {
    notifications: {
      type: Number,
      default() {
        return 0;
      },
    },
    loadingNotifications: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapGetters("queue", ["getQueue"]),
    ...mapGetters("auth", ["isLogged", "permissions", "settings"]),
    ...mapActions("sidebar", ["saveNavbar"]),
    ...mapActions("queue", [/* "popQueue", "pushQueue", */ "emptyQueue"]),
    logout() {
      this.$store
        .dispatch("auth/logout")
        .then(() => {
          this.$store.dispatch("filters/removeAll");
          this.$store.dispatch("tabs/removeAll");
          this.$store.dispatch("sorts/removeAll");
          this.$store.dispatch("pagination/removeAll");
          this.$store.dispatch("queue/emptyQueue");
          this.$store.dispatch("breadcrumbs/remove");
          document.body.className = "Body";
          this.isNavClose
            ? this.saveNavbar(this.isNavClose)
            : this.saveNavbar(!this.isNavClose);
          this.$router.replace({ name: "Login" });
          this.$showSnackbar({
            preset: "success",
            text: "Uscita con successo",
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
  },
  computed: {
    ...mapActions("breadcrumbs", { removeBCS: "remove" }),
    cliVer() {
      return process.env.VUE_APP_VERSION || "0";
    },
    agencyName() {
      return this.$store.state.auth.settings.formatted_title;
    },
  },
  watch: {
    //Watch queue-store changes
    "$store.state.queue.items": {
      //If you want to watch nested elements, you need to use the deep flag in your watcher
      deep: true,
      handler() {
        this.breadcrumblist = this.getQueue();
        let len = this.breadcrumblist.length;
        this.pageTitle =
          len === 1 ? { text: "Dashboard" } : this.breadcrumblist[len - 1];
        this.clearedBreadcrumbList = this.breadcrumblist
          ? [...this.breadcrumblist.slice(1, this.breadcrumblist.length)]
          : [];
      },
    },
  },
};
</script>
<style lang="scss" scoped></style>
