<template>
  <component :is="iconComponent" role="img" class="custom-svg-icon" />
</template>

<script>
const icons = {
  close: require("@/assets/icons/CloseIcon.vue").default,
  difference: require("@/assets/icons/DifferenceIcon.vue").default,
  loading: require("@/assets/icons/LoadingIcon.vue").default,
  sepa: require("@/assets/icons/SepaIcon.vue").default,
  address_book: require("@/assets/icons/AddressBookIcon.vue").default,
  fattutto: require("@/assets/icons/Fattutto.vue").default,
  send: require("@/assets/icons/Send.vue").default,
};

export default {
  name: "BaseIcon",
  props: {
    name: {
      type: String,
      required: true,
      validator(value) {
        return Object.prototype.hasOwnProperty.call(icons, value);
      },
    },
  },

  computed: {
    iconComponent() {
      return icons[this.name];
    },
  },
};
</script>
