var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"drawer"},[_c('div',{staticClass:"notifications-drawer"},[_c('div',{staticClass:"drawer__overlay",attrs:{"id":"NotificationsDrawer-Overlay"},on:{"click":function($event){return _vm.onClickCloseNotificationsDrawer()}}}),_vm._v(" "),_c('div',{staticClass:"drawer__inner",attrs:{"role":"dialog","aria-modal":"true","aria-label":"Notifiche","tabindex":"-1"}},[_c('div',{staticClass:"drawer__header"},[_c('div',{staticClass:"drawer__heading inner-title"},[_vm._v("\n          Promemoria"+_vm._s(_vm.notifications.length ? ` (${_vm.notifications.length})` : "")+"\n        ")]),_vm._v(" "),_c('button',{staticClass:"drawer__close",attrs:{"type":"button","aria-label":"Chiudi"},on:{"click":function($event){return _vm.onClickCloseNotificationsDrawer()}}},[_c('base-icon',{attrs:{"name":"close","width":"12","height":"12"}})],1)]),_vm._v(" "),_c('div',{class:`notifications-drawer-items${
          !_vm.notifications.length ? ' is-empty' : ''
        }`},[(_vm.notifications.length)?[_c('b-overlay',{attrs:{"show":_vm.isLoadingNotifications},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center mt-6"},[_c('base-icon',{attrs:{"name":"loading","width":"35","height":"35"}}),_vm._v(" "),_c('p',{attrs:{"id":"cancel-label"}},[_vm._v("Operazione in corso...")])],1)]},proxy:true}],null,false,3745726629)},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoadingNotifications),expression:"!isLoadingNotifications"}]},_vm._l((_vm.notifications),function(item,index){return _c('div',{key:index,staticClass:"notification-item"},[_c('div',{staticClass:"notification-item__inner"},[_c('p',{staticClass:"notification-item__title"},[_c('strong',[_vm._v(_vm._s(item.title))])]),_vm._v(" "),(item.description)?_c('p',{staticClass:"notification-item__desc"},[_vm._v("\n                    "+_vm._s(item.description)+"\n                  ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"custom-grid-wrapper"},[(
                        Object.prototype.hasOwnProperty.call(
                          item,
                          'task_type'
                        )
                      )?[_c('strong',[_vm._v("Tipologia")]),_c('span',{staticClass:"notification-item__type"},[_vm._v(_vm._s(item.task_type.text))])]:_vm._e(),_vm._v(" "),(item.started_at && item.task_type.value === 0)?[_c('strong',[_vm._v("Data inizio")]),_c('span',{staticClass:"notification-item__start"},[_vm._v(_vm._s(_vm.moment(item.started_at).format("DD/MM/YYYY HH:mm:ss")))])]:_vm._e(),_vm._v(" "),(item.ended_at && item.task_type.value === 0)?[_c('strong',[_vm._v("Data fine")]),_c('span',{staticClass:"notification-item__start"},[_vm._v(_vm._s(_vm.moment(item.ended_at).format("DD/MM/YYYY HH:mm:ss")))])]:_vm._e(),_vm._v(" "),(item.status_task && item.task_type.value === 1)?[_c('strong',[_vm._v("Stato")]),_c('span',{staticClass:"notification-item__status"},[_vm._v(_vm._s(item.status_task.text))])]:_vm._e(),_vm._v(" "),(
                        Object.prototype.hasOwnProperty.call(
                          item,
                          'is_public'
                        ) &&
                        Object.prototype.hasOwnProperty.call(
                          item.is_public,
                          'text'
                        )
                      )?[_c('strong',[_vm._v("È pubblico")]),_c('span',{staticClass:"notification-item__start"},[_vm._v(_vm._s(item.is_public.text))])]:_vm._e(),_vm._v(" "),(item.owner_id != null && item.created_at)?[_c('strong',[_vm._v("Attività aperta da")]),_c('span',{staticClass:"notification-item__users",domProps:{"innerHTML":_vm._s(_vm.getOwner(item.owner_id, item.created_at))}})]:_vm._e(),_vm._v(" "),(item.users.length)?[_c('strong',[_vm._v("Attività condivisa con")]),_c('span',{staticClass:"notification-item__users"},[_vm._v("\n                        "+_vm._s(_vm.getUsersList(item.users)))])]:_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"notification-item__actions"},[(
                        _vm.isDeletable(item.owner_id) &&
                        Object.prototype.hasOwnProperty.call(item, 'memo') &&
                        Object.prototype.hasOwnProperty.call(item.memo, 'id')
                      )?_c('b-button',{attrs:{"size":"sm","variant":"lisaweb","title":"Elimina promemoria"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteMemo(item.memo.id)}}},[_c('b-icon',{attrs:{"icon":"trash"}})],1):_vm._e()],1)])])}),0)])]:[_vm._m(0)]],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('i',[_vm._v("Non ci sono promemoria")])])
}]

export { render, staticRenderFns }